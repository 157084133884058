import React, { useEffect, useState } from "react";
import Database from "../_utils/Database";
import { goto } from "../_utils/Misc";
import Layout from "../_components/Layout/Layout";
import Reward from "../_components/Reward/Reward";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";

const AllRewards = () => {
    const [bodyMessage, setBodyMessage] = useState(null);
    const [rewardsList, setRewardsList] = useState(null);

    useEffect(() => {
        if (!Database.getUserToken()) {
            goto("/");
            return null;
        }

        // Make request
        fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/rewards`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Database.getUserToken()}`,
            },
        }).then(async(response) => {
            const responseObject = await response.json();

            if (responseObject && responseObject.rewards) {
                setRewardsList(responseObject.rewards);
            } else {
                setBodyMessage(`We cannot process your request now. Please try again later.`);
            }
        }).catch(e => {
            console.error("Fetch reward error", e);
            setBodyMessage(`We cannot process your request now. Please try again later.`);
        });
    }, []);

    return (
        <Layout title="Rewards">
            <MainTitle>HERE ARE YOUR TREATS!</MainTitle>

            {bodyMessage && <PageSummary>{bodyMessage}</PageSummary>}

            {rewardsList && rewardsList.map((reward, key) => (
                <Reward
                    key={`reward-${key}`}
                    image={reward.reward_image_url}
                    title={reward.reward_title}
                    description={reward.reward_message}
                />
            ))}
        </Layout>
    );
};

export default AllRewards;
