import React from "react"
import "./styles.scss"

const Reward = (props) => {
    const { image, title, description } = props

    return (
        <div className="reward">
            <img
                src={image}
                alt={title}
            />

            <div className="info-box">
                <p className="title"> {title}</p>
            </div>
            <p className="text">
                {description}
            </p>
        </div>
    )
}

export default Reward
